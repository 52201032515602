<template>
  <div class="table-responsive">
    <table
      class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
    >
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-50px">Name</th>
          <th class="min-w-100px">Created by</th>
          <th class="min-w-100px">Company</th>
          <th class="min-w-120px text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="4"><LoadingSpinner></LoadingSpinner></td>
        </tr>
        <tr v-else-if="getData.length == 0">
          <td colspan="4">
            <el-empty
              description="No templates matching filter were found."
            ></el-empty>
          </td>
        </tr>
        <tr v-for="item in getData" :key="item.id" v-else>
          <td>
            <span class="text-dark fw-bolder text-hover-primary d-block fs-6">{{
              item.name
            }}</span>
          </td>
          <td>
            <router-link
              :to="'/users/' + item.created_by_id + '/personal'"
              class="text-dark fw-bolder text-hover-primary fs-6"
              >{{ item.created_by }}</router-link
            >
          </td>
          <td>
            <router-link
              v-if="item.company_id"
              :to="'/client/' + item.company_id"
              class="text-dark fw-bolder text-hover-primary fs-6"
              >{{ item.company_name }}</router-link
            >
          </td>
          <td class="text-end">
            <div class="btn-group btn-group-sm pb-2 pt-1" role="group">
              <router-link
                :to="`/jobs/templates/${item.id}`"
                class="btn btn-sm btn-warning"
              >
                Edit
              </router-link>
              <button
                class="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                @click="$emit('openDeleteModal', item)"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :currentPage="pagination.currentPage"
      :totalPages="pagination.lastPage"
      @changeCurrentPage="handleChangePage"
    ></Pagination>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Pagination from "@/components/pagination/Pagination.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";

export default defineComponent({
  name: "job-template-data-table",
  components: {
    LoadingSpinner,
    Pagination
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      lastPage: 1
    }
  }),
  computed: {
    ...mapGetters("JobTemplatesModule", ["getData", "getPagination", "loading"])
  },
  methods: {
    ...mapActions("JobTemplatesModule", ["changeCurrentPage"]),
    handleChangePage(page) {
      this.changeCurrentPage(page);
    }
  },
  updated() {
    this.pagination = this.getPagination;
  },
  mounted() {
    this.pagination = this.getPagination;
  }
});
</script>
