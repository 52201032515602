<template>
  <JobTemplatesIndex></JobTemplatesIndex>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import JobTemplatesIndex from "@/components/job-templates/Index.vue";

export default defineComponent({
  name: "edit-job-view",
  components: {
    JobTemplatesIndex
  },
  mounted() {
    setCurrentPageTitle("Job templates");
  }
});
</script>
