<template>
  <DeleteModal
    :currentlySelectedEntry="
      selectedForDelete !== null ? selectedForDelete.name : ''
    "
    @delete="handleDelete"
  ></DeleteModal>
  <div class="card">
    <div class="card mb-5 mb-xl-8">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title flex-column justify-content-between">
          <span class="card-label fw-bolder fs-3 mb-1">Job templates</span>

          <span class="text-muted mt-1 fw-bold fs-7"
            >Overview of job templates</span
          >
        </h3>
        <div>
          <router-link
            to="/jobs/create?template=true"
            class="btn btn-sm btn-success ms-auto "
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="media/icons/duotone/Code/Plus.svg" />
            </span>
            Create New Template
          </router-link>
        </div>
      </div>

      <div class="card-body py-3">
        <div class="row">
          <div class="col-12 mb-2">
            <div class="d-flex align-items-center justify-content-end">
              <div>
                <SearchField
                  :placeholder="'Search by template name'"
                  class="me-4"
                  @change="handleSearchByName"
                ></SearchField>
              </div>
              <div>
                <CompaniesDropdown
                  @change="handleSearchByClient"
                  v-if="!$can('view_only_own_company_jobs', 'all')"
                ></CompaniesDropdown>
              </div>
            </div>
          </div>
          <JobTemplatesDataTable
            class="col-12"
            @openDeleteModal="handleDeleteModal"
          ></JobTemplatesDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import JobTemplatesDataTable from "@/components/job-templates/JobTemplatesDataTable.vue";
import { mapActions } from "vuex";
import DeleteModal from "@/components/general/DeleteModal.vue";
import CompaniesDropdown from "@/components/dropdown/ClientDropdown.vue";
import SearchField from "@/components/general/SearchField.vue";

export default defineComponent({
  name: "job-templates-index",
  components: {
    SearchField,
    CompaniesDropdown,
    JobTemplatesDataTable,
    DeleteModal
  },
  data: () => ({
    selectedForDelete: null
  }),
  methods: {
    ...mapActions("JobTemplatesModule", [
      "fetchData",
      "resetState",
      "destroyData",
      "searchByClient",
      "searchByName"
    ]),
    handleDeleteModal(data) {
      console.log(data);
      this.selectedForDelete = data;
    },
    handleDelete() {
      this.destroyData(this.selectedForDelete.id);
    },
    handleSearchByClient(event) {
      this.searchByClient(event);
    },
    handleSearchByName(event) {
      this.searchByName(event.target.value);
    }
  },
  beforeMount() {
    if (!this.$can("job_template_access", "all")) {
      this.$router.push("/");
    }
    this.fetchData();
  },
  beforeUnmount() {
    this.resetState();
  }
});
</script>
